/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import FrontPageService from './services/front-page.service'

document.addEventListener('DOMContentLoaded', () => {
    new FrontPageService()
})
